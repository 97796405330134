<template>
  <div class="head">
    <div class="left">
      <img src="../../assets/op_02.jpg" alt="">
      <div class="content">
        <div class="ctop">分时领英操作手册</div>
        <div class="cbotom">智赋云 提供</div>
        <div class="ft">
          <a href="#" target="blink"><img style="width:20px;height:20px" src="../../assets/download.svg" alt=""> 下载</a>
        </div>
      </div>
    </div>
    <div class="right">
      <img src="../../assets/op_03.jpg" alt="">
      <div class="content">
        <div class="ctop">分时领英开票范围</div>
        <div class="cbotom">智赋云 提供</div>
        <div class="ft">
          <a href="#" target="blink"><img style="width:20px;height:20px" src="../../assets/download.svg" alt=""> 下载</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style scoped lang="less">
.head{
  display: flex;
  align-content: center;
  // background: #F6F6F6;
  .left,.right{
    width:240px;
    height:240px;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.07);
    img{
      width: 240px;
      height: 140px;
    }
    .content{
      padding: 14px 16px;
      background: #FFFFFF;
      border-radius: 0 0 12px 12px;
      .ctop{
        font-size: 16px;
        color: #0e1726;
        font-weight: bold;
        line-height: 20px;
        margin-bottom: 2px;
      }
      .cbton{
        line-height: 20px;
        font-size: 12px;
        color: #9fa2a8;
      }
      .ft{
        display: flex;
        justify-content: flex-end;
        a{
          color: #9fa2a8;
          &:hover{
            color: #1890ff;
          }
        }
      }
    }
  }
  .right{
    margin-left: 20px;
  }
}
</style>